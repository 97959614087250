<template>
  <div id="gtin-search" :class="classes">
    <Form v-slot="{ validate }" class="search-form" :class="{ loading: loading.gtin, hidden: !showSearchForm }" as="div">
      <div class="gtin-search__errors">
        <GS1Modal v-for="(searchError, i) in searchErrors" :key="i" :active="true" initial @close="closeSearchError(i)">
          <template #default>
            <div class="gtin-search__errors__container">
              <span v-if="searchError.header" class="gtin-search__errors__container__header">{{ searchError.header }}</span>
              <span class="font-gray display-secondary gtin-search__errors__container__body">
                {{ searchError.message.replace(/3\sсек\.?$/, '') }}
                <b v-if="searchError.code === 'E002'" class="gtin-search__errors__container__cooldown">{{ limits.requestCooldown }}сек</b>
              </span>
              <span v-if="searchError.code === 'E001'" class="gtin-search__errors__container__footer">
                <span class="font-gray">{{ gtinNumberList.length }} /</span> {{ limits.maxGtins }}
              </span>
            </div>
          </template>
        </GS1Modal>
      </div>

      <GS1Curtain
        v-model:active="gtinFormActive"
        class="gtin-search__left-container"
        :toggleable="gtinFormTogglable"
        :width="260"
        toggle-tooltip="Ввод GTIN"
        big-toggle
      >
        <template #default>
          <h1 class="gtin-search__left-container__header-text">Ввод списка GTIN</h1>
          <GS1TextareaBarcodeUploader
            ref="textareaUploader"
            v-model="gtinNumbers"
            v-model:files="fileList"
            placeholder="Введите GTIN"
            class="barcode-uploader__gtin-input"
            transparent
            :upload-button="true"
            :info-button="true"
            :style="`width: 100%; height: 100%; margin-bottom: 16px;`"
            :loading="fileError ? 100 : fileList[0]?.loading || 0"
            :success-message="fileList[0]?.success"
            :error-message="fileError || null"
            @upload="onFilesUploaded"
          >
            <template #modalContent>
              <div class="barcode-uploader__info">
                <div class="barcode-uploader__info__heading">Тех. требования к запросу</div>
                <div class="circle-progress-bar__container barcode-uploader__info__value">
                  <GS1ProgressBarCircle class="circle-progress-bar__circle-element" :value="limitPercent">
                    <div class="circle-progress-bar__text font-gray">
                      Лимит GTIN в одном запросе:
                      <span class="font-black display-bold">
                        {{
                          gtinNumberList.length >= parseInt(limits.maxGtins) ? 'Лимит превышен' : parseInt(limits.maxGtins) - gtinNumberList.length
                        }}
                      </span>
                    </div>
                  </GS1ProgressBarCircle>
                </div>
                <div class="font-gray barcode-uploader__info__value">
                  <GS1Icon icon="time" small></GS1Icon>
                  Частота запроса:&nbsp;<span class="font-black"> {{ limits.requestCooldown }} сек</span>
                </div>
              </div>
            </template>
          </GS1TextareaBarcodeUploader>

          <div class="gtin-search__actions">
            <div class="actions__text-container">
              <div class="gtin-search__example">
                <span class="font-gray">
                  Пример запроса:
                  <a class="font-black display-bold gtin-search__example__gtin" href="#" @click.prevent="searchExample('04630080790056')">
                    04630080790056
                  </a>
                </span>
              </div>
            </div>
            <GS1Btn
              style="width: 100%"
              no-margin
              primary
              :disabled="parseInt(limits.maxGtins) == 0 || gtinNumbers.length == 0"
              @click="search(validate)"
            >
              Искать
            </GS1Btn>
          </div>
        </template>
        <template #toggle>
          <GS1Icon icon="keyboard"></GS1Icon>
          Ввести GTIN
        </template>
      </GS1Curtain>
      <div class="gtin-search__right-container">
        <div v-if="showTemplate" class="no-content-template">
          <div v-if="gtinNumbers.length == 0 && showGsTemplate" class="no-content-template__modal SfDisplay">
            <GS1BtnIcon
              class="modal__close-button"
              color="#A7ACB1"
              transparent
              size="default"
              :icon="['close', 'default']"
              @click="onCloseGsTemplate"
            ></GS1BtnIcon>
            <div class="modal__icon-container">
              <GS1Icon class="description__active-icon" tiny color="#7AC143" icon="success"></GS1Icon>
              <h2 class="display-large font-wbold SfDisplay gs-no-margin no-content-template__modal__header-text">Verified by GS1</h2>
            </div>

            <span class="display-large font-gray no-content-template__modal__main-text">Информация по товарам от производителей всего мира</span>
            <span class="active-icon__text font-gray display-secondary no-content-template__modal__footer-text">
              Данные предоставлены напрямую производителями
            </span>
          </div>
          <div class="modal__text-container">
            <h2 class="main-text__no-content display-medium font-w-b modal__text-container__header-text font-sf-display">
              Еще нет результатов поиска
            </h2>
            <span class="font-gray display-small modal__text-container__main-text font-sf-display">
              Здесь будут результаты поиска по введенным GTIN
            </span>
          </div>
        </div>
        <GS1Loader v-if="loader"></GS1Loader>
        <div v-if="!showTemplate && !loader" ref="tableContainer" class="gtin-search__table-container">
          <GS1TableFlat
            v-model:search="searchString"
            v-bind="{
              fields: tableFields,
              data: gtins,
              displayedCount: gtins.length,
              totalCount: gtins.length,
              linkable: true,
              showRange: false,
              showHeader: true,
              showFooter: false,
              rowActiveField: 'gtin',
              rowActive: [currentActiveGtin],
            }"
            @record-click="openApi"
          >
            <template #header>
              <div class="gtin-search__table__heading-default display-bold">Результаты поиска</div>
              <GS1Tabs class="gtin-search__header-filter__container">
                <GS1TabsItem :active="gtinTypeView === 0" @click="setGtinViewType(0)">Все</GS1TabsItem>
                <GS1TabsItem :active="gtinTypeView === 1" @click="setGtinViewType(1)">Успешные</GS1TabsItem>
                <GS1TabsItem :active="gtinTypeView === 2" @click="setGtinViewType(2)">С ошибками</GS1TabsItem>
              </GS1Tabs>
            </template>
            <template #header-right>
              <GS1DropDownMenu :icon="['download']" label="Скачать все" class="gtin-search__table-download-btn green">
                <div class="gtin-search__table-download-btn__heading">Экспорт в формате:</div>
                <GS1DropDownMenuItem @click="exportGtins('xlsx', true, true)">Скачать XLS файл</GS1DropDownMenuItem>
                <GS1DropDownMenuItem @click="exportGtins('csv', true, true)">Скачать CSV файл</GS1DropDownMenuItem>
              </GS1DropDownMenu>
            </template>
            <template #label-actions>
              <GS1InputMultiCheck v-model="selectAll" no-margin></GS1InputMultiCheck>
            </template>
            <template #actions="slot">
              <GS1InputCheck v-model="selection[slot.item.gtin]" no-margin></GS1InputCheck>
            </template>
            <template #description="slot">
              <div class="description__text">
                <!-- Реализовано так, потому, что vue ругается на условие, если оно применено к самому img -->
                <div v-if="(!slot.item?.hasError && !slot.item.noInfo) || (slot.item.onlyPro && user && user.pro)" class="small-image">
                  <div
                    :class="{
                      'small-image__container': true,
                      'dark-placeholder': productImages[slot.index][0].isPlaceholder,
                    }"
                  >
                    <img :key="`gtin-search-item-image-${slot.item.gtin}`" :src="productImages[slot.index][0].value" />
                  </div>
                </div>
                <div v-else class="small-image"></div>
                <div v-if="!slot.item.hasError" class="description__active-icon description__active-icon--success"></div>
                <div v-if="slot.item.hasError" class="description__active-icon"></div>
                <div v-if="slot.item.nkStatus" class="description__active-icon description__active-icon--success__nk-image"></div>
                <div v-if="!slot.item.hasError" class="description__text-container">
                  <div v-if="slot.item.licenseInfo?.licenceStatus == 'ACTIVE'">{{ getProductDescription(slot.item) }}</div>
                  <div v-if="slot.item.licenseInfo?.licenceStatus == 'ACTIVE'" class="description__text-container__source">
                    Владелец номера:
                    {{ slot.item.licenseInfo ? slot.item.licenseInfo.licenseeName : '' }}
                  </div>
                  <div v-else-if="slot.item.licenseInfo?.licenceStatus == 'INACTIVE'" class="description__text-container__source">
                    {{ slot.item?.error }}
                  </div>
                  <div v-if="slot.item?.errorNote" class="error-note">{{ slot.item?.errorNote }}</div>
                </div>
                <div v-if="slot.item.hasError" class="">
                  <div class="name">
                    {{ slot.item.error }}
                  </div>
                  <div v-if="slot.item?.errorNote" class="error-note">
                    {{ slot.item?.errorNote }}
                    <GS1Icon
                      v-if="slot.item?.errorExtraNote?.length > 0"
                      v-gs1-tooltip.right="slot.item?.errorExtraNote"
                      icon="info-circle"
                      class="gtin-search__extra-info-icon"
                    ></GS1Icon>
                  </div>
                </div>
              </div>
            </template>
            <template #gtin="slot">
              <span :class="{ error: slot.item.hasError }">{{ slot.item.gtin }}</span>
            </template>
          </GS1TableFlat>
          <GS1ItemsSelector
            class="gtin-search__bottom-items-selector"
            v-bind="{
              total: gtins.length,
              selected: selected.length,
              active: selected.length > 0,
              showCancelBtn: false,
            }"
          >
            <GS1DropDownMenu
              ref="exportMenuComponent"
              :icon="['download']"
              accent
              label="&#8203;"
              class="gtin-search__table-language green"
              open-above
            >
              <template #label>{{ exportButtonLabel }}</template>
              <template #default>
                <GS1InputCheck
                  v-for="language in exportLanguages"
                  :key="`gtin-lang-${language.value}`"
                  v-model="selectedExportLanguages[language.value]"
                  :value="language.value"
                >
                  {{ language.label }}
                </GS1InputCheck>
                <div class="gtin-search__table-language__heading">Экспорт в формате:</div>
                <GS1DropDownMenuItem @click="exportGtins('xlsx')">Скачать XLS файл</GS1DropDownMenuItem>
                <GS1DropDownMenuItem @click="exportGtins('csv')">Скачать CSV файл</GS1DropDownMenuItem>
              </template>
            </GS1DropDownMenu>
            <GS1Btn accent no-margin @click="uncheckAll">
              <template #left>
                <GS1Icon icon="close-square"></GS1Icon>
              </template>
              Снять выбор
            </GS1Btn>
          </GS1ItemsSelector>
        </div>
      </div>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, onUnmounted, onBeforeUnmount, watch, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Form } from 'vee-validate';
import { debounce } from '../../../utils/debounce';
import User from '@/modules/auth/models/User';
import type { GS1TableField } from '@gs1ru/web-components';
import dayjs from 'dayjs';
import config from '@/config';

export default defineComponent({
  name: 'Search',
  components: { Form },
  emits: ['check'],
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);
    const bulkSearchFile: Ref<any> = ref(null);

    /**
     * Чекбокс "Выбрать все"
     */
    const selectAll: Ref<number> = ref(0);

    /**
     * Окно с информацией
     */
    const infoModal: Ref<any> = ref(null);

    /**
     * Активность окна с информацией
     */
    const activeInfo: Ref<boolean> = ref(false);

    /**
     * Активность формы ввода GTIN
     */
    const gtinFormActive: Ref<boolean> = ref(true);

    /**
     * Возможность раскрыть форму ввода GTIN
     */
    const gtinFormTogglable: Ref<boolean> = ref(false);

    /**
     * Текущая страница
     */
    const page: Ref<number> = ref(1);

    /**
     * Кол-во элементов на страницу
     */
    const perPage: Ref<number> = ref(10);

    /**
     * Поисковой запрос
     */
    const searchString: Ref<string> = ref('');

    /**
     * Номера GTIN
     */
    const gtinNumbers: Ref<string> = ref('');

    /**
     * Выбранные элементы
     */
    const checkedItems: Ref<string[]> = ref([]);

    // Переменная для фильтрации gtin (Успешные, с ошибкой, все)
    const gtinTypeView: Ref<number> = ref(0);

    const exportMenuComponent: Ref<any> = ref(null);
    const fileList: Ref<any[]> = ref([]);
    const fileError = ref('');

    const onFilesUploaded = (files: any[]) => {
      const file = files?.at(0);
      if (!file) return;
      fileError.value = '';
      fileList.value = files;
      const fileReader = new FileReader();

      fileReader.readAsText(file.file);
      fileReader.addEventListener('loadend', () => {
        if (Math.ceil(file.file.size / 1024 / 1024) > (config?.maxFileSize || 2))
          return (fileError.value = `Размер файла превышает ${config?.maxFileSize} MB`);
        if (file.file.type !== 'text/plain') return (fileError.value = 'Файл имеет некорректный формат');
        fileList.value[0].loading = 100;
        fileList.value[0].success = 'Файл успешно загружен!';
        gtinNumbers.value += fileReader.result as string;
      });

      fileList.value = files;
    };

    const currentActiveGtin = computed(() => route?.params?.gtin || '');

    const tableFields: Array<GS1TableField> = [
      { label: '', field: 'actions', sort: false, width: '35px', drag: false, fixed: true },
      {
        label: 'Описание',
        field: 'description',
        sort: false,
        lineClamp: 1,
      },
      {
        label: 'GTIN',
        field: 'gtin',
        sort: false,
        width: '150px',
        lineClamp: 1,
        fixed: true,
      },
    ];

    /**
     * Ошибка запроса
     */
    const requestError: Ref<string> = ref('');

    /**
     * Список выбранных языков для экспорта
     */
    const selectedExportLanguages: Ref<Record<string, boolean>> = ref({});
    const languagesToExport = computed(() => {
      if (!selectedExportLanguages.value) return [];
      const result: Array<string> = [];
      Object.entries(selectedExportLanguages.value).map(([key, value]) => {
        if (value) result.push(key);
      });
      return [...new Set(result)];
    });

    const unsetSelectedExportLanguages = () => (selectedExportLanguages.value = Object.assign({}, {}));

    /**
     * Список выбранных файлов для массового поиска
     */
    const bulkSearchFiles: Ref<Array<File>> = ref([]);

    /**
     * Показывать диалог для экспорта
     */
    const showExportDialog: Ref<boolean> = ref(false);

    /**
     * Показывать диалог "Выделить все"
     */
    const showCheckAllDialog: Ref<boolean> = ref(false);

    /**
     * Файл со списком GTIN для поиска
     */
    const hasBulkSearchFiles: Ref<boolean> = ref(false);

    /**
     * Формат экспорта
     */
    const exportFormat: Ref<string> = ref('xlsx');

    /**
     * Отображение формы поиска
     */
    const showSearchForm: Ref<boolean> = ref(true);

    /**
     * Состояние чекбокса "Выбрать все"
     */
    const checkAllChecked: Ref<boolean> = ref(false);

    /**
     * Элемент выбран
     */
    const selection: Ref<Record<string, boolean>> = ref({});

    const selected = computed(() => Object.values(selection.value).filter((i: boolean) => i));

    /**
     * Объект авторизованного пользователя
     */
    const user: ComputedRef<User> = computed(() => store.state.auth.user);

    /**
     * Массив GTIN
     */
    const gtinNumberList: ComputedRef<Array<string>> = computed(() =>
      gtinNumbers.value
        .replaceAll(/[.,;\s]/g, '\n')
        .split('\n')
        .filter((i) => !!i)
    );

    /**
     * Массив GTIN для поиска с уникальными значениями
     */
    const uniqueGtinNumberList: ComputedRef<Array<string>> = computed(() => {
      const gtinNumberArray = gtinNumberList.value.filter((gtinNumber) => gtinNumber.length > 0);
      return [...new Set(gtinNumberArray)];
    });

    const newfoundGtins = computed(() => store.state.gtinSearch.gtins);

    /**
     * Массив всех найденных GTIN
     */
    const allGtins: ComputedRef<Record<string, any>> = computed(() => {
      const foundGtins = store.state.gtinSearch.gtins;
      const gtinNumberList = foundGtins.filter(
        (gtin: Record<string, any>) =>
          !searchString.value ||
          gtin?.gtin?.includes(searchString.value) ||
          gtin?.licenseeName?.toLowerCase().includes(searchString.value.toLowerCase()) ||
          gtin?.productDescription?.some((description: any) => description?.value?.toLowerCase().includes(searchString.value.toLowerCase()))
      );

      return gtinNumberList.map((gtin: Record<string, any>) => {
        gtin.show = false;
        return gtin;
      });
    });

    const productImages: Ref<Record<string, string | boolean>[][]> = ref([]);

    /**
     * Запрос  конвертации tiff изображений
     *
     * @param imagePath
     * @param gtinNumber
     */
    const convertTiff = (imageUrl: string, gtinNumber: string, index: string) => {
      return store.dispatch('gtinSearch/convertTiff', {
        imageUrl: imageUrl,
        gtinNumber: gtinNumber,
        index: index,
      });
    };

    const logoImage = computed(() => {
      if (ViewMode.value === 'dark') return require('./../../../assets/placeholderDark.svg');
      else if (ViewMode.value === 'light') return require('./../../../assets/placeholder.svg');
      else return require('./../../../assets/placeholder.svg');
    });

    /**
     * Массив найденных GTIN с постраничной навигацией
     */
    const gtins: ComputedRef<Record<string, any>> = computed(() => {
      let filteredGtins = allGtins.value;
      if (gtinTypeView.value) {
        filteredGtins = filteredGtins.filter((gtin: Record<string, any>) => {
          if (gtinTypeView.value === 1) return !gtin.hasError;
          else if (gtinTypeView.value === 2) return !!gtin.hasError;
        });
      }
      return filteredGtins.map((gtin: Record<string, any>, index: number) => {
        const classes = [];

        if (typeof gtin.gtin !== 'undefined') {
          classes.push('gtin-' + gtin.gtin);
        }

        if (gtin.hasError) {
          classes.push('has-error');

          if (typeof gtin.licenseInfo !== 'undefined') {
            classes.push('has-license');
          }
        }
        if (gtin.gtinRecordStatus == 'DISCONTINUED') {
          classes.push('no-info');
        }

        gtin.classes = classes.join(' ');

        productImages.value[index] = [];

        if (gtin.productImageUrl && gtin.productImageUrl.length > 0) {
          gtin.productImageUrl.forEach((productImage: Record<string, string>, imageIndex: number) => {
            if (productImage?.value == 'Маркировка') productImages.value[index][imageIndex] = { value: logoImage.value, isPlaceholder: true };
            else if (productImage.value.split('.').pop()?.split('?')[0] == 'tiff') {
              convertTiff(productImage.value, gtin.gtin, imageIndex.toString()).then((newImageUrl: string) => {
                productImages.value[index][imageIndex].value = newImageUrl;
              });
              productImage.valueOriginal = productImage.value;
              productImages.value[index][imageIndex] = { value: logoImage.value, isPlaceholder: true };
            } else {
              if (productImage?.value?.indexOf('/placeholder.png') + 1)
                productImages.value[index]?.push({ value: logoImage.value, isPlaceholder: true });
              else productImages.value[index]?.push(productImage);
            }
          });
        } else {
          productImages.value[index]?.push({ value: logoImage.value, isPlaceholder: true });
        }

        return gtin;
      });
    });

    /**
     * Флаг отображения результата
     */
    const showGtinResult: ComputedRef<boolean> = computed(() => store.state.gtinSearch.showGtinResult);

    /**
     * Флаг говорящий о том, был ли найден GTIN
     */
    const foundGtin: ComputedRef<boolean> = computed(() => store.state.gtinSearch.foundGtin);

    const textareaUploader: any = ref(null);

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    const loader: Ref<boolean> = ref(false);

    /**
     * Лимиты запросов
     */
    const limits: ComputedRef<Record<string, string>> = computed(() => store.state.gtinSearch.limits);

    /**
     * Тип полученного результата
     */
    const resultType: ComputedRef<string> = computed(() => (foundGtin.value ? 'success' : 'warning'));

    /**
     * Показать шаблон для правого меню
     */
    const showTemplate: Ref<boolean> = ref(true);

    /**
     * Массив ошибок поиска
     */
    const searchErrors: Ref<Array<Record<string, any>>> = ref([]);

    /**
     * Показать шаблон GS1 для правого меню
     */
    const showGsTemplate: Ref<boolean> = ref(true);

    const tableContainer: Ref<any> = ref(null);
    const tableWidth: Ref<number> = ref(0);
    let resizeTimeout: ReturnType<typeof setTimeout> | undefined;
    const getTableWidth = () => {
      tableWidth.value = tableContainer.value?.getBoundingClientRect()?.width;
    };
    const observer = new MutationObserver(getTableWidth);

    const handleResize = debounce(() => {
      getTableWidth();
    }, 100);

    onMounted(() => {
      window.addEventListener('resize', handleResize);
      if (tableContainer.value) {
        observer.observe(tableContainer.value, { attributes: true, childList: true, subtree: true });
      }
      getTableWidth(); // Вычисляем значение после монтирования
    });

    // Удаляем слушатель события и остановливаем таймер при демонтаже компонента
    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(resizeTimeout);
      observer.disconnect();
    });

    watch(
      () => {
        return {
          containerWidth: tableContainer.value?.getBoundingClientRect()?.height,
        };
      },
      () => {
        getTableWidth();
      },
      { deep: true } // Глубокая отслеживание изменений в объекте
    );

    const gtinsToExport: ComputedRef<Array<string>> = computed(
      () =>
        Object.entries(selection.value)
          .filter((element: [string, boolean]) => element[1])
          ?.map((element: [string, boolean]) => element[0]) || []
    );

    /**
     * Список языков для экспорта
     */
    const exportLanguages: ComputedRef<Record<string, string>[]> = computed(() => {
      let result: string[] = [];
      const checkedGtins = gtinsToExport.value;

      allGtins.value.forEach((gtin: Record<string, any>) => {
        if (checkedGtins.includes(gtin.gtin) && typeof gtin.languages !== 'undefined') result = result.concat(gtin.languages);
      });

      result = [...new Set(result)];

      const languageObject = result.map((language: string) => {
        return { value: language, label: language };
      });

      return languageObject;
    });

    /**
     * Текс кнопки экспорта
     */
    const exportButtonLabel: ComputedRef<string> = computed(() => {
      if (exportLanguages.value.length > 0) {
        return 'Выбрать язык и скачать';
      } else {
        return 'Скачать выбранные';
      }
    });

    /**
     * Кол-во массовых поисков
     */
    const bulkSearchAmount: ComputedRef<Record<string, string>> = computed(() => {
      return {
        active: store.state.gtinSearch.activeBulkResults,
        unfinished: store.state.gtinSearch.unfinishedBulkResults,
        success: store.state.gtinSearch.successBulkResults,
        error: store.state.gtinSearch.errorBulkResults,
        total: store.state.gtinSearch.totalBulkResults,
      };
    });

    const getAllLanguages = computed<string[]>(() => {
      const uniqueLanguages: Record<string, boolean> = {};

      // Проход по всем элементам в allGtins и добавление языков в uniqueLanguages
      allGtins.value.forEach((item: any) => {
        if (item.languages && Array.isArray(item.languages)) {
          item.languages.forEach((lang: string) => {
            uniqueLanguages[lang] = true;
          });
        }
      });

      return Object.keys(uniqueLanguages);
    });

    /**
     * Результат поиска содержит ошибки
     */
    const hasErrors: ComputedRef<boolean> = computed((): boolean => {
      return allGtins.value.some((gtin: Record<string, any>) => gtin.hasError) && !!showGtinResult.value;
    });

    const TableContainerWidth: Ref<any> = ref(null);
    const getTableContainerWidth = () => (TableContainerWidth.value = tableContainer?.value?.getBoundingClientRect()?.width);

    const isLabelSmall = computed(() => {
      console.log(TableContainerWidth.value > 844);
      getTableContainerWidth();
      if (TableContainerWidth.value > 844) {
        return false;
      } else return true;
    });

    const limitPercent: ComputedRef<number> = computed(() => Math.ceil((gtinNumberList.value.length * 100) / parseInt(limits.value.maxGtins)));

    onMounted(() => {
      store.dispatch('gtinSearch/getLimits');
      store.dispatch('auth/getUser');
    });

    /**
     * Экспорт списка GTIN
     *
     * @param format Формат файла списка
     * @param all Скачать все результаты, а не только выбранные
     */
    const exportGtins = (format = 'xlsx', all = false, allLanguages = false): void => {
      let gtinNumbers = [];

      if (all) {
        gtinNumbers = gtins.value.map((gtin: Record<string, any>) => gtin.gtin);
      } else {
        gtinNumbers = gtinsToExport.value;
      }

      const languagesToExport = allLanguages
        ? getAllLanguages.value
        : Object.keys(selectedExportLanguages.value).reduce((acc: any, lang: any) => {
            if (selectedExportLanguages.value[lang]) {
              acc.push(lang);
            }
            return acc;
          }, []);

      store
        .dispatch('gtinSearch/export', {
          gtinNumbers: gtinNumbers,
          languages: languagesToExport,
          format: format,
        })
        .then(() => {
          store.commit('setOverlay', false);
          showExportDialog.value = false;
        });
    };

    /**
     * Сброс указанных значений GTIN
     */
    const resetGtinFields = (): void => {
      gtinNumbers.value = '';
    };

    const disabledTextarea = ref(gtinNumberList.value.length >= parseInt(limits.value.maxGtins));

    /**
     * Преобразования значения списка
     */
    const transformGtinList = (): void => {
      gtinNumbers.value = uniqueGtinNumberList.value.join('\n');
    };

    /**
     * Выбор элемента из списка результатов
     */
    const checkItem = (item: Record<string, any>) => {
      if (item.checked) checkedItems.value.push(item.gtin);
      else checkedItems.value = checkedItems.value.filter((gtin) => gtin != item.gtin);
      unsetSelectedExportLanguages();
    };

    /**
     * Выбор всех элементов или только элементов на текущей странице
     */
    const checkAll = (checkAll: boolean): void => {
      const _gtins = checkAll ? allGtins : gtins.value;

      checkedItems.value = _gtins.map((gtin: Record<string, any>) => gtin.gtin);

      store.commit('setOverlay', false);
      showCheckAllDialog.value = false;
    };

    /**
     * Выбор всех элементов
     */
    const openCheckAllDialog = (): void => {
      if (checkAllChecked.value) {
        showCheckAllDialog.value = true;
        store.commit('setOverlay', true);
        store.commit('setOverlayCallback', () => {
          showCheckAllDialog.value = false;
          checkAll(false);
        });
      } else checkedItems.value = [];
    };

    /**
     * Показывать диалог для загрузки списка массового поиска
     */
    const showBulkSearchDialog: Ref<boolean> = ref(false);

    /**
     * Пример поиска
     */
    const searchExample = (exampleGtin: string): void => {
      gtinNumbers.value = exampleGtin;
    };

    /**
     * Открытие окна экспорта
     */
    const openExportDialog = (): void => {
      showExportDialog.value = true;
      store.commit('setOverlay', true);
      store.commit('setOverlayCallback', () => {
        showExportDialog.value = false;
      });
    };

    /**
     * Открытие диалога загрузки списка GTIN
     */
    const openBulkSearchDialog = (): void => {
      store.commit('setOverlay', true);
      showBulkSearchDialog.value = true;
      store.commit('setOverlayCallback', () => {
        showBulkSearchDialog.value = false;
      });
    };

    /**
     * Выбор файла для массовго поиска GTIN
     */
    const bulkSearchFileChosen = (input: EventTarget | null): void => {
      const files = (input as HTMLInputElement).files;

      if (files && files.length) {
        hasBulkSearchFiles.value = true;
        bulkSearchFiles.value = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          const extension = file.name.split('.').pop() || '';
          const allowedExtensions = ['txt'];

          if (allowedExtensions.includes(extension)) bulkSearchFiles.value.push(file);
        }
      }
    };

    /**
     * Загрузка файла для массового поиска GTIN
     */
    const uploadBulkSearchFile = (): void => {
      const input = bulkSearchFile.value;
      const files = bulkSearchFiles.value;

      if (files && files.length)
        files.forEach((file: File) => {
          const timestamp = dayjs().unix();

          store.commit('setOverlay', false);
          showBulkSearchDialog.value = false;
          hasBulkSearchFiles.value = false;

          router.push({ name: 'gtin-search-bulk' });

          store.commit('gtinSearch/addBulkSearchItem', {
            id: timestamp,
            created_at_fromatted: dayjs().format('DD.MM.YYYY/HH:mm'),
            processed_at_formatted: '',
            urls: [],
            file_name: file.name,
            percent: 0,
            status_name: 'Загружается',
            status_note: '',
            status_color: 'default',
            has_errors: 0,
          });

          store
            .dispatch('gtinSearch/uploadBulkSearchFile', {
              input: input?.name,
              file: file,
              timestamp: timestamp,
            })
            .then((result: Record<string, any>) => {
              if (!result?.success) return;
              store.dispatch('gtinSearch/getActiveBulkResults');
              store.commit('gtinSearch/setLoaded', {
                fileName: file.name,
                timestamp: timestamp,
                id: result.response.id,
                statusName: result.response.status_name,
                createdAt: result.response.created_at_formatted,
              });
              router.push({ name: 'gtin-search-bulk' });
            });
        });
    };

    /**
     * Переключение состояния формы поиска
     */
    const toggleSearchForm = (): void => {
      showSearchForm.value = !showSearchForm.value;
    };

    /**
     * Скрытие плашки GS1
     */
    const onCloseGsTemplate = (): void => {
      showGsTemplate.value = false;
    };

    /**
     * Подпись select для выбора языка
     */
    const exportLanguagesLabel = (): string =>
      'Языки: ' + languagesToExport.value.slice(0, 3).join(', ') + (languagesToExport.value.length > 3 ? '...' : '');

    /**
     * Удаление файла массовлого поиска для загрузки
     */
    const removeBulkSearchFile = (key: number) => bulkSearchFiles.value.splice(key, 1);

    /**
     * Поиск по номеру GTIN
     */
    const search = (): void => {
      if (!gtinNumbers.value.length) return;
      router.push({ name: 'gtin-search-search' });

      transformGtinList();
      unsetSelectedExportLanguages();

      loader.value = true;
      showTemplate.value = false;
      searchString.value = '';
      checkedItems.value = [];
      searchErrors.value = [];
      selection.value = {};
      selectAll.value = 0;

      store
        .dispatch('gtinSearch/searchGtins', uniqueGtinNumberList.value)
        .then((result: Record<string, any>) => {
          if (!result.success) {
            searchErrors.value.push({
              header: result.header,
              code: result.code,
              message: result.message,
            });
            showTemplate.value = true;
          } else {
            gtinFormTogglable.value = true;
            gtinFormActive.value = false;
          }
          requestError.value = !result.success ? result.message : '';
        })
        .finally(() => {
          loader.value = false;
          showGsTemplate.value = false;
        });
    };

    // Открывает страницу с детальной информацией по баркоде
    const openApi = (e: Event, gtin: any) => {
      if (!gtin || (gtin?.hasError && typeof gtin.licenseInfo === 'undefined')) {
        return;
      }

      router.push({ name: 'gtin-search-result', params: { gtin: gtin.gtin } });
    };

    onUnmounted(() => {
      const routeWithoutGtin = { ...route };
      delete routeWithoutGtin.params.gtin;

      // Выполняем перенаправление на новый маршрут без параметра 'gtin'
      router.replace(routeWithoutGtin);
    });

    // Возвращает описание товара для баркода (т.к. их там несколько - на разных языках)
    const getProductDescription = (product: Record<string, any>) => {
      if (product?.productDescription) {
        let name = product.productDescription.find((name: Record<string, string>) => name.language == 'ru');
        if (!name) name = product.productDescription[product.productDescription.length - 1];
        return name.value;
      }
      return '';
    };

    const setGtinViewType = (value: number) => {
      gtinTypeView.value = value;
    };

    // Открытие/закрытие окна с информацией
    const toggleInfo = (e: Event) => {
      activeInfo.value = !activeInfo.value;

      if (typeof e !== 'undefined') {
        let toggleInfoButton: HTMLElement | null = e.target as HTMLElement;
        if (!toggleInfoButton.classList.contains('gs1-btn-icon')) {
          toggleInfoButton = toggleInfoButton.closest('.gs1-btn-icon');
        }

        if (toggleInfoButton && infoModal.value) {
          const toggleButtonOffsets = toggleInfoButton.getBoundingClientRect();
          const topOffset = toggleButtonOffsets.top + 43;
          const leftOffset = toggleButtonOffsets.left - 111;
          const infoModalWindow = infoModal.value.$el.querySelector('.gs1-modal__window');
          if (infoModalWindow) {
            infoModalWindow.style.left = leftOffset + 'px';
            infoModalWindow.style.top = topOffset + 'px';
          }
        }
      }
    };

    // Убрать выделение со всех элементов поиска
    const uncheckAll = () => {
      selection.value = {};
    };

    // Закрытие ошибки поиска
    const closeSearchError = (index: number) => {
      searchErrors.value.splice(index, 1);
    };

    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        'gtin-search': true,
        ['mode-' + ViewMode.value]: true,
      };
    });

    watch(
      () => exportLanguages.value,
      () => {
        if (exportLanguages.value.length === 1) {
          selectedExportLanguages.value = { [exportLanguages.value[0].value]: true };
        } else {
          selectedExportLanguages.value = {};
          const checkedGtins = Object.keys(selection.value);

          if (typeof exportLanguages.value.find((language: Record<string, string>) => language.value == 'ru') !== 'undefined') {
            selectedExportLanguages.value.ru = true;
          }

          allGtins.value.forEach((gtin: Record<string, any>) => {
            if (checkedGtins.includes(gtin.gtin) && typeof gtin.languages !== 'undefined' && gtin.languages.length > 0) {
              selectedExportLanguages.value[gtin.languages[0]] = true;
            }
          });
        }
      }
    );
    /**
     * Скрытие карточки с результатом поиска при изменении значений GTIN для поиска
     */
    watch(
      () => gtinNumbers,
      () => {
        store.commit('gtinSearch/setShowGtinResult', false);
        requestError.value = '';
      }
    );
    watch(
      () => searchString,
      () => {
        checkedItems.value = [];
      }
    );
    watch(
      () => gtinNumberList.value,
      (newValue) => {
        if (newValue.length == 0) textareaUploader?.value?.onFileDelete();
      }
    );
    /**
     * Логика выбора элементов
     */
    watch(
      () => selectAll.value,
      () => {
        if (selectAll.value == 0) {
          uncheckAll();
        } else if (selectAll.value == 1) {
          gtins.value.forEach((gtin: Record<string, any>) => {
            selection.value[gtin.gtin] = true;
          });
        }
      }
    );
    watch(
      () => selected.value,
      () => {
        if (selected.value.length == 0) {
          selectAll.value = 0;
        } else if (selected.value.length == gtins.value.length) {
          selectAll.value = 1;
        } else {
          selectAll.value = -1;
        }
      }
    );
    watch(
      () => router.currentRoute.value.params,
      () => {
        // Класс добавляется таким образом, потому что если это делать через св-во classes элемента таблицы GS1TableFlat, то данные строки мигают при изменении класса
        document.querySelector('.gtin-search .gs1-table-flat__table-tr.opened')?.classList.remove('opened');

        if (typeof router.currentRoute.value.params.gtin !== 'undefined' && router.currentRoute.value.params.gtin) {
          document.querySelector('.gs1-table-flat__table-tr.gtin-' + router.currentRoute.value.params.gtin)?.classList.add('opened');
        }
      },
      {
        deep: true,
      }
    );
    watch(
      () => gtinsToExport.value,
      () => {
        if (exportMenuComponent.value && gtinsToExport.value.length == 0) {
          exportMenuComponent.value.onBlur();
        }
      }
    );
    watch(
      () => fileList.value.length,
      (newValue) => {
        if (newValue == 0) gtinNumbers.value = '';
      }
    );

    return {
      page,
      perPage,
      searchString,
      gtinNumbers,
      gtinTypeView,
      checkedItems,
      requestError,
      selectedExportLanguages,
      languagesToExport,
      bulkSearchFiles,
      showExportDialog,
      showCheckAllDialog,
      hasBulkSearchFiles,
      showSearchForm,
      user,
      gtinNumberList,
      uniqueGtinNumberList,
      allGtins,
      gtins,
      showTemplate,
      checkAllChecked,
      showGtinResult,
      foundGtin,
      exportFormat,
      loading,
      newfoundGtins,
      selection,
      limits,
      limitPercent,
      resultType,
      gtinsToExport,
      exportLanguages,
      bulkSearchAmount,
      hasErrors,
      showBulkSearchDialog,
      tableFields,
      showGsTemplate,
      loader,
      disabledTextarea,
      gtinFormActive,
      gtinFormTogglable,
      activeInfo,
      infoModal,
      selected,
      selectAll,
      searchErrors,
      tableContainer,
      isLabelSmall,
      classes,
      tableWidth,
      fileList,
      fileError,
      textareaUploader,
      onFilesUploaded,
      currentActiveGtin,
      search,
      onCloseGsTemplate,
      openApi,
      exportGtins,
      resetGtinFields,
      transformGtinList,
      checkItem,
      openCheckAllDialog,
      checkAll,
      searchExample,
      openExportDialog,
      unsetSelectedExportLanguages,
      openBulkSearchDialog,
      bulkSearchFileChosen,
      uploadBulkSearchFile,
      toggleSearchForm,
      exportLanguagesLabel,
      removeBulkSearchFile,
      getProductDescription,
      setGtinViewType,
      toggleInfo,
      uncheckAll,
      closeSearchError,
      productImages,
      exportButtonLabel,
      exportMenuComponent,
    };
  },
});
</script>

<style lang="scss">
.gtin-search {
  display: flex;
  min-width: 100%;
  height: calc(100% + (var(--margin-x4) * 2));
  margin: calc(var(--margin-x4) * -1);

  .gtin-search__errors {
    display: flex;
    flex-direction: column;
    width: 226px;
    top: 0;
    left: 0;
    position: absolute;
    top: var(--margin-x7);
    left: var(--margin-x3);
    .gs1-element.gs1-modal.active {
      width: 100%;
      height: auto;
      position: static;
    }
    .gs1-modal + .gs1-modal {
      margin-top: var(--margin-x35);
    }
    .gs1-modal__window {
      color: var(--color-main-5);
      height: auto;
      width: calc(100% - var(--gs1-padding-x3)) !important;
      position: relative;
      transform: none;
      left: 0;
      margin-top: 0 !important;
    }
    .gs1-modal__window__close.min {
      margin: var(--margin-x2);
      width: 18px;
      height: 18px;
      .gs1-icon__icon {
        width: 18px;
        height: 18px;
      }
    }
    .gtin-search__errors__container {
      display: flex;
      flex-direction: column;

      .gtin-search__errors__container__header {
        margin-bottom: var(--margin-x2);
        font-weight: 600;
        padding-right: 20px;
      }
      .gtin-search__errors__container__body {
        .gtin-search__errors__container__cooldown {
          color: var(--color-main-2);
        }
      }
      .gtin-search__errors__container__footer {
        margin-top: var(--margin-x2);
      }
    }
  }

  .gtin-search__bottom-items-selector {
    .gs1-element.gs1-btn.accent {
      margin-left: var(--margin-x2);
    }
  }

  .gs1-element.gs1-items-selector {
    left: 50%;
    margin-top: var(--margin-x3);
    &.active {
      bottom: 16px;
    }
    .gs1-element.gs1-btn-icon-edit {
      color: var(--color-main-f);
      .gs1-icon__icon {
        background-color: var(--color-main-f);
      }
    }
    .gs1-dropdown-menu + .gs1-dropdown-menu {
      margin-left: var(--margin-x2);
    }
    .gs1-element.gs1-btn-icon-edit .gs1-btn-icon-edit__icon {
      margin: 0 var(--margin-x15) 0 2px;
    }
  }

  .search-form {
    display: flex;
    width: 100%;
    position: relative;
  }
  .actions__text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: var(--padding-x3);
    .gtin-search__example {
      font-size: 12px;
      .display-bold {
        text-decoration: underline;
      }
    }
  }

  .gtin-search__right-container {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--padding-x4);
    padding-left: 0;

    .gs1-table-flat__table-tr:not(.sthead),
    &.active {
      background-color: var(--color-main-14);
      &:hover,
      &.active {
        background-color: var(--color-main-15) !important;
      }
      &.has-error {
        background-color: var(--color-main-13);
        .small-image::after {
          background-color: var(--color-main-5);
        }
        &.has-license {
          cursor: pointer;
        }
        &:hover,
        &.active {
          background-color: var(--color-main-16) !important;
        }
      }

      &.no-info {
        background-color: var(--color-additional-18);
        &:hover,
        &.active {
          background-color: var(--color-main-17) !important;
        }
        .small-image::after {
          background-color: var(--color-main-18);
        }
      }
      &:not(.has-error) {
        cursor: pointer;
      }
      &.opened {
        background-color: var(--color-main-19);
        &.has-error {
          background-color: var(--color-main-20);
        }
        &.no-info {
          background-color: var(--color-main-17);
          color: var(--color-main-2);
        }
      }
    }
    .gs1-element.gs1-loader .gs1-loader__spinner .gs1-loader__spinner__path {
      color: transparent; //Сделал пока тут, в следующей версии библиотеки, вынесу эту правку туда, а тут удалю
    }
    .gs1-element.gs1-table-flat {
      .gs1-table-flat__header .gs1-element.gs1-search {
        width: auto;
        margin: 0;
      }
      .gs1-table-flat__table-tr.error {
        background-color: var(--color-main-12);
      }
      .gs1-table-flat__table-td-value {
        font-size: 13px;
        color: var(--color-main-3);
        .gs1-table-flat__table-td-value__content {
          display: flex;
          height: auto !important;
          padding: var(--padding-x15) 0;
        }
      }
      .gs1-element.gs1-input-check .gs1-input-check__label,
      .gs1-input-multi-check .gs1-input-multi-check__label {
        &::before,
        &::after {
          width: 16px;
          height: 16px;
        }
      }
    }

    .no-content-template__modal {
      display: flex;
      flex-direction: column;
      padding: var(--padding-x3);
      justify-content: space-between;
      align-items: flex-start;
      position: absolute;
      width: 100%;
      height: 116px;
      border-radius: var(--border-radius-x2);
      border: 1px solid var(--color-additional-19);
      background-color: var(--color-main-28);

      .modal__icon-container {
        display: flex;
        align-items: center;

        .active-icon__text {
          padding-left: var(--padding-x2);
        }
      }
      .modal__close-button {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .no-content-template {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      height: 100%;

      .modal__text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;

        .main-text__no-content {
          padding-bottom: var(--padding-x2);
          margin: auto;
        }
      }
    }
    .gtin-search__table-counter {
      margin: auto var(--margin-x4);
    }
    .gs1-table-flat__header-right {
      gap: var(--margin-x3);
      .gs1-element.gs1-modal.initial-position .gs1-modal__window {
        right: 0;
        left: initial;
      }
    }
    .gtin-search__table-language-blue {
      .gtin-search__table-download-btn {
        margin-left: var(--margin-x2);
      }

      .gs1-element.gs1-btn-icon-edit {
        background-color: var(--color-main-8);
        &:hover {
          background-color: var(--color-additional-20);
        }
      }
    }
    .green {
      .gs1-element.gs1-btn-icon-edit {
        margin-left: var(--margin-x2);
        background-color: var(--color-main-7);
        &:hover,
        &:active {
          background-color: var(--color-additional-4);
        }
      }
      &.mode-light {
        .gs1-element.gs1-btn-icon-edit {
          .gs1-btn-icon-edit__value {
            color: var(--color-main-f);
          }
          .gs1-icon__icon {
            background-color: var(--color-main-f);
          }
          &:hover {
            .gs1-icon__icon {
              background-color: var(--color-main-f);
            }
          }
        }
      }
    }
    .gtin-search__table-download-btn__container {
      .gtin-search__table-download-btn {
        margin-left: var(--margin-x3);
      }
      .gs1-element.gs1-btn-icon-edit .gs1-btn-icon-edit__icon {
        margin: 0 var(--margin-x15) 0 0;
      }
    }
    .gtin-search__table-language,
    .gtin-search__table-download-btn {
      .gs1-element.gs1-modal.initial-position .gs1-modal__window {
        padding: var(--padding-x3);
        .gs1-input-check {
          margin-left: 0;
        }
      }
      .gtin-search__table-language__heading,
      .gtin-search__table-download-btn__heading {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: var(--margin-x3);
        &:not(:first-child) {
          margin-top: var(--margin-x3);
        }
      }
      .gs1-element.gs1-btn-icon-edit {
        margin: 0 !important;
        text-align: center;
      }
    }
    .gtin-search__table__heading {
      font-size: 14px;
      margin-right: var(--margin-x3);
      font-family: 'SF UI Display';
      &:hover {
        text-decoration: underline;
      }
    }
    .gtin-search__table__heading-default {
      font-size: 14px;
      margin-right: var(--margin-x3);
      &:hover {
        text-decoration: none;
      }
    }
    .description__text {
      display: flex;
      align-items: center;
      flex: 1 1 100%;
    }
    .description__text-container {
      display: flex;
      flex-direction: column;
    }
    .description__text-container__source,
    .error-note {
      font-size: 12px;
      margin-top: var(--margin-x1);
    }
    .error-note {
      color: var(--color-main-5);
      display: flex;
      align-items: center;
    }

    .description__active-icon {
      margin: 0 var(--margin-x2) 0 0;
      flex-shrink: 0;
    }
    .description__active-icon--success {
      background-image: url(@/assets/images/vbg-square.png);
      width: 20px;
      height: 20px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    .description__active-icon--success__nk-image {
      background-image: url(@/assets/images/nk-image.svg);
      width: 20px;
      height: 20px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    .small-image {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 0 43px;
      width: fit-content;
      height: 32px;
      margin-right: var(--margin-x2);
      .small-image__container {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius-x1);
        background-color: var(--color-main-f);
      }
      img {
        max-height: 95%;
        max-width: 95%;
      }
      &::after {
        width: var(--border-thick);
        flex: 0 0 var(--border-thick);
        height: 100%;
        background-color: var(--color-main-7);
        border-radius: var(--border-radius-x0);
        margin-left: var(--margin-x2);
        content: '';
      }
    }
    .loader-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .gtin-search__table-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      .header-text {
        margin: 0;
      }
      .error {
        color: var(--color-main-5);
      }

      .gs1-element.gs1-table-flat {
        .gs1-table-flat__header {
          margin: 0 0 var(--margin-x3);
          gap: var(--margin-x3);
          flex-wrap: wrap;
          height: auto;
        }
        .gs1-table-flat__header__slot {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .gs1-element.gs1-btn-icon-edit {
            margin: auto 0 auto auto;
            width: 120px;
          }
        }
        .gs1-table-flat__header-left {
          gap: var(--margin-x3);
        }
        .gs1-table-flat__header-counter {
          margin: 0;
        }
      }

      .gtin-search__status {
        display: flex;
        align-items: center;

        .gs1-element.gs1-icon {
          margin: 0 var(--margin-x2) 0 0;
        }

        &.error {
          color: var(--color-main-5);
          .gs1-element.gs1-icon {
            i {
              background-color: var(--color-main-5);
            }
          }
        }
        &.success {
          color: var(--color-main-7);
          .gs1-element.gs1-icon {
            i {
              background-color: var(--color-main-7);
            }
          }
        }
      }
      .gtin-search__table-btn__container {
        display: flex;
        flex-direction: row-reverse;
        .gs1-element.gs1-modal.absolute-position .gs1-modal__window {
          /* left: 85% !important;
          top: 5% !important; */
        }
      }
    }
  }
  &.mode-dark {
    .gtin-search__left-container {
      background-color: var(--color-main-24);

      .gtin-search__left-container__header-text {
        color: var(--color-main-f);
      }
    }
    .actions__text-container {
      .gtin-search__example {
        .font-gray {
          color: var(--color-main-4);
        }
        .display-bold {
          text-decoration-color: var(--color-main-4);
        }

        .gtin-search__example__gtin {
          color: var(--color-main-f);
        }
      }
    }

    .gs1-element.gs1-progress-bar-circle {
      .circle-progress-bar__text {
        color: var(--color-main-4);
        .font-black {
          color: var(--color-main-f);
        }
      }
    }

    .gtin-search__right-container {
      .small-image {
        .small-image__container {
          background-color: var(--color-main-f);
        }
        .dark-placeholder {
          background-color: var(--color-main-27) !important;
        }
      }
    }

    .gtin-search__errors {
      .gtin-search__errors__container {
        .gtin-search__errors__container__header {
          color: var(--color-additional-2);
          .font-gray {
            color: var(--color-additional-2);
          }
        }
        .gtin-search__errors__container__body {
          color: var(--color-main-f);
        }
        .gtin-search__errors__container__footer {
          color: var(--color-additional-2);
          .font-gray {
            color: var(--color-main-f);
          }
        }
        .gtin-search__errors__container__cooldown {
          color: var(--color-main-f);
        }
      }
    }

    .no-content-template__modal {
      background-color: var(--color-additional-14);
      border: 1px solid var(--color-main-31);
      .no-content-template__modal__header-text {
        color: var(--color-main-7);
      }
      .no-content-template__modal__main-text {
        color: var(--color-main-f);
      }
      .no-content-template__modal__footer-text {
        color: var(--color-main-f);
      }
    }
    .modal__text-container {
      .modal__text-container__header-text {
        color: var(--color-main-f);
      }
      .modal__text-container__main-text {
        color: var(--color-main-4);
      }
    }

    .barcode-uploader__info {
      .barcode-uploader__info__value {
        color: var(--color-main-4);
        .font-black {
          color: var(--color-main-f);
        }
      }
      .gs1-element.gs1-icon .gs1-icon__icon {
        background-color: var(--color-main-f);
        &:hover {
          background-color: var(--color-main-f);
        }
      }
    }

    .gtin-search__table__heading {
      color: var(--color-main-f);
    }
    .gtin-search__table__heading-default {
      color: var(--color-main-f);
    }

    .gtin-search__right-container {
      .gs1-element.gs1-table-flat {
        .gs1-table-flat__table-td-value {
          color: var(--color-main-f);
        }
      }

      .gs1-table-flat__table-tr:not(.sthead),
      &.active {
        background-color: var(--color-additional-15) !important;
        &:hover,
        &.active {
          background-color: var(--color-additional-16) !important;
        }
        &.has-error {
          background-color: var(--color-danger-900) !important;
          .small-image::after {
            background-color: var(--color-main-5) !important;
          }
          &.has-license {
            cursor: pointer;
          }
          &:hover,
          &.active {
            background-color: var(--gs1-color-danger-1000) !important;
          }
        }

        &.no-info {
          background-color: var(--color-main-30) !important;
          &:hover,
          &.active {
            background-color: var(--color-main-29) !important;
          }
          .small-image::after {
            background-color: var(--color-main-18) !important;
          }
        }
        &:not(.has-error) {
          cursor: pointer;
        }
        &.opened {
          background-color: var(--color-additional-16) !important;
          &.has-error {
            background-color: var(--gs1-color-danger-1000) !important;
          }
          &.no-info {
            background-color: var(--color-main-29) !important;
          }
        }
      }
    }
  }
}

.gtin-search__left-container {
  background-color: var(--color-additional-9);
  .gs1-curtain__toggle .gs1-icon__icon {
    transition: all var(--transition-super-fast-smooth);
  }
  &.hidden .gs1-curtain__toggle .gs1-icon__icon {
    transform: rotate(180deg);
  }
  .gs1-curtain__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
  }

  .circle-progress-bar__container {
    margin: 0 0 var(--margin-x3) 0;
  }
  .gtin-search__actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    .gs1-element.gs1-btn.disabled {
      border-color: transparent;
    }
  }
  h1 {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: var(--margin-x3);
  }
}
.barcode-uploader__info {
  width: auto !important;
  height: auto !important;
  .barcode-uploader__info__heading {
    font-size: 13px;
    font-weight: 600;
  }
  .barcode-uploader__info__value {
    margin-top: var(--margin-x3);
    display: flex;
    align-items: center;
    > .gs1-icon {
      margin-right: var(--margin-x2);
    }
  }
  .gs1-element.gs1-progress-bar-circle {
    justify-content: flex-start;
    .circle-progress-bar__text {
      font-size: 12px;
    }
  }
  .gs1-modal__window {
    transform: none !important;
  }
  .gs1-element.gs1-icon .gs1-icon__icon {
    background-color: var(--color-main-9);
    &:hover {
      background-color: var(--color-main-9);
    }
  }
}
.gs1-element.gs1-textarea.barcode-uploader__gtin-input {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  flex-grow: 1;
  margin: 0 0 var(--margin-x3) 0;
  position: relative;

  .gs1-textarea__container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    .gs1-textarea__input-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex-grow: 1;
      .gs1-textarea__input {
        flex-grow: 1;
        max-height: 100%;
        padding-right: var(--padding-x65);
      }
    }
  }
}
.gtin-search__actions {
  display: flex;
  width: 100%;
}
.gtin-search__extra-info-icon {
  display: inline-flex !important;
  margin-left: var(--margin-x1) !important;
}
</style>
