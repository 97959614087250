import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a5c4952"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gtin-result-header__container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.gtin)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.classes)
        }, _toDisplayString(_ctx.gtin?.gtin), 3))
      : _createCommentVNode("", true),
    (_ctx.gtin?.nkStatus)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "gtin-result-header__nk-image",
          src: _ctx.nkImage
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}